<template>
  <div class="reservation">
    <formHeader />

    <div class="content">
      <v-card class="mx-auto form-card" v-if="!loading">
        <v-row>
          <v-col cols="12" sm="6" class="right-col">
            <v-card-text>
              <v-row class="mx-0">
                <v-col class="text-center">
                  <h3 class="mb-2 brand-name white-text">
                    {{ reservationData.salon_name }}
                  </h3>
                  <p class="gray-text location mb-0">
                    {{ reservationData.branch_name }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" class="">
                  <p
                    class="success-msg text-center"
                    style="color: #a53860"
                    dir="rtl"
                  >
                    لقد قدمت طلبك بنجاح.
                  </p>
                  <p
                    class="success-msg text-center"
                    style="color: #a53860"
                    dir="rtl"
                  >
                    ستصلك رسالة من الصالون قريباً
                  </p>
                </v-col>
                <v-col cols="12" sm="12" class="">
                  <div class="reg-details">
                    <!-- <v-row>
                      <v-col
                        cols="12"
                        sm="8"
                        style="padding: 5px 10px; color: #000"
                        ><h3 class="black-text">بيانات التسجيل</h3></v-col
                      >
                    </v-row> -->
                    <v-row>
                      <v-col
                        cols="6"
                        sm="5"
                        class="black-text"
                        style="padding: 5px 10px"
                        >الإسم:</v-col
                      >
                      <v-col
                        cols="6"
                        sm="7"
                        class="gray-text"
                        style="padding: 5px 10px"
                        >{{ reservationData.full_name }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="5"
                        class="black-text"
                        style="padding: 5px 10px"
                        >الجوال:</v-col
                      >
                      <v-col
                        cols="6"
                        sm="7"
                        class="gray-text"
                        style="padding: 5px 10px"
                        >{{ reservationData.mobile }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="5"
                        class="black-text"
                        style="padding: 5px 10px"
                        >التاريخ:</v-col
                      >
                      <v-col
                        cols="6"
                        sm="7"
                        class="gray-text"
                        style="padding: 5px 10px"
                        >{{ reservationData.reservation_date }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="5"
                        class="black-text"
                        style="padding: 5px 10px"
                        >الزمن:</v-col
                      >
                      <v-col
                        cols="6"
                        sm="7"
                        class="gray-text"
                        style="padding: 5px 10px"
                        >{{ reservationData.reservation_time }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="5"
                        class="black-text"
                        style="padding: 5px 10px"
                        >الجلسات:</v-col
                      >
                      <v-col
                        cols="6"
                        sm="7"
                        class="gray-text"
                        style="padding: 5px 10px"
                        >{{ reservationData.sessions }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="5"
                        class="black-text"
                        style="padding: 5px 10px"
                        >مقر الحجز:</v-col
                      >
                      <v-col
                        cols="6"
                        sm="7"
                        class="gray-text"
                        style="padding: 5px 10px"
                        >{{ reservationData.reservation_at }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="5"
                        class="black-text"
                        style="padding: 5px 10px"
                        >عدد الجلسات:</v-col
                      >
                      <v-col
                        cols="6"
                        sm="7"
                        class="gray-text"
                        style="padding: 5px 10px"
                        >{{ reservationData.sessions_count }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="5"
                        class="black-text"
                        style="padding: 5px 10px"
                        >إجمالي سعر الجلسات:</v-col
                      >
                      <v-col
                        cols="6"
                        sm="7"
                        class="gray-text"
                        style="padding: 5px 10px"
                        >{{ reservationData.sessions_total_fees }} ر.س</v-col
                      >
                    </v-row>
                    <v-row v-if="reservationData.home_service_fees > 0">
                      <v-col
                        cols="6"
                        sm="5"
                        class="black-text"
                        style="padding: 5px 10px"
                        >مصاريف خدمة المنزل:</v-col
                      >
                      <v-col
                        cols="6"
                        sm="7"
                        class="gray-text"
                        style="padding: 5px 10px"
                        >{{ reservationData.home_service_fees }} ر.س</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="5"
                        class="black-text"
                        style="padding: 5px 10px"
                        >ضريبة القيمة المضافة:</v-col
                      >
                      <v-col
                        cols="6"
                        sm="7"
                        class="gray-text"
                        style="padding: 5px 10px"
                        >{{ reservationData.vat }} ر.س</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="5"
                        class="black-text"
                        style="padding: 5px 10px"
                        >الإجمالي:</v-col
                      >
                      <v-col
                        cols="6"
                        sm="7"
                        class="gray-text"
                        style="padding: 5px 10px"
                        >{{ reservationData.total_after_vat }} ر.س</v-col
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col cols="12" sm="6" class="left-col">
            <!-- :position="google && new google.maps.LatLng(1.38, 103.8)" -->
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" class="text-center" v-if="showMap">
                  <GmapMap
                    :center="center"
                    :zoom="7"
                    ref="mapRef"
                    style="
                      width: 100%;
                      height: 700px;
                      margin: 0 auto;
                      border-radius: 20px;
                    "
                    :options="{
                      zoomControl: true,
                      mapTypeControl: true,
                      scaleControl: true,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: true,
                      disableDefaultUi: false,
                    }"
                  >
                    <gmap-marker
                      :key="index"
                      v-for="(m, index) in markers"
                      :position="m.position"
                      :clickable="true"
                      :draggable="true"
                      @click="center = m.position"
                    ></gmap-marker>
                  </GmapMap>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as VueGoogleMaps from "vue2-google-maps";
import formHeader from "../components/formHeader";

export default {
  name: "AfterReservation",
  components: { formHeader },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      time: null,
      menu2: false,
      reservationData: {},
      loading: true,
      center: { lat: 24.7136, lng: 46.6753 },
      markers: [
        {
          position: { lat: 24.7136, lng: 46.6753 },
        },
      ],
      showMap: true,
    };
  },
  methods: {
    getReservationData() {
      this.$http
        .get(
          this.base_url + "/salon/branches/reservation/" + this.$route.params.id
        )
        .then((response) => {
          if (response.data.data == null) {
            this.$router.push({ path: "/404" });
          } else {
            this.reservationData = response.data.data;
            this.loading = false;
            this.center = {
              lat: response.data.data.branch.lat
                ? parseFloat(response.data.data.branch.lat)
                : 24.7136,
              lng: response.data.data.branch.lng
                ? parseFloat(response.data.data.branch.lng)
                : 46.6753,
            };

            this.markers[0].position.lat = response.data.data.branch.lat
              ? parseFloat(response.data.data.branch.lat)
              : 24.7136;
            this.markers[0].position.lng = response.data.data.branch.lng
              ? parseFloat(response.data.data.branch.lng)
              : 46.6753;
          }
        });
    },
  },
  mounted() {
    this.getReservationData();
  },
  computed: {
    ...mapGetters(["base_url"]),
    google: VueGoogleMaps.gmapApi,
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "jannaLtBold";
  src: local("jannaLtBold"),
    url(../assets/fonts/JannaLTBold/JannaLTBold.ttf) format("truetype");
}
$second-color: #ff7dac;
* {
  font-family: "jannaLtBold";
}
.white-text {
  color: #000;
}
.gray-text {
  color: #888888 !important;
}
.black-text {
  color: #000 !important;
}
.second-text {
  color: #ff7dac;
}

.reg-details {
  border: 1px solid #a53860;
  padding: 20px;
  border-radius: 25px;
}

.content {
  // height: 100%;
  .title,
  .brand-name {
    font-size: 1.5rem !important;
  }
  .location {
    font-size: 1rem;
  }
  // background-color: #e5e5e5;
  // padding-bottom: 50px;
  //   height: 50vh;
  .form-card {
    width: 950px;
    // overflow: auto;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 1rem;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent !important;
    box-shadow: none !important;

    .right-col {
      // height: 630px;
      border-top: 5px solid #a53860;
      border-radius: 0 0 90px 0;
      padding: 1rem 2rem;
      background-color: #fff;
      color: #ffffff;
      margin-bottom: 10px !important;
    }
    .left-col {
      // height: 630px;
      border-bottom: 5px solid #fff;
      border-radius: 90px 0 0 0;
      padding: 1rem 2rem;
      background-color: #2d3533;
      color: #ffffff;
      margin-top: 10px;
    }
    .bottom-img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 150px;
      opacity: 0.5;
    }
  }
}

.rounded-input {
  border-radius: 20px;
}
.countrycode {
  direction: ltr !important;
  text-align: center !important;
  padding-right: 0 !important;
}
.countrycode-col {
  margin-right: 0 !important;
  padding-right: 0 !important;
}
.countrycode-col .v-text-field__slot input {
  text-align: center !important;
}
.main-btn {
  background-color: #a53860 !important;
  color: #ffffff !important;
  border-radius: 5px 5px 20px 5px;
  width: 150px;
  font-size: 1.2rem !important;
}

.success-content {
  .success-msg {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .reg-details {
    border: 1px solid #cccccc;
    padding: 1rem 2rem;
    color: #ffffff;
    border-radius: 20px;
    ul {
      list-style: none;
    }
  }
}
.text-center {
  text-align: center !important;
}

@media (min-width: 960px) {
  .form-card {
    max-width: 50%;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .form-card {
    max-width: 80%;
  }
  .right-col {
    padding: 12px !important;
  }
}

@media (max-width: 600px) {
  .form-card {
    max-width: 80%;
  }

  .form-card {
    .right-col {
      border-radius: 0 !important;
      padding: 1rem 0 !important;
    }

    .left-col {
      border-radius: 0 !important;
      padding: 1rem 0 !important;
      .vue-map-container {
        height: 400px !important;
      }
    }
  }
}
</style>
